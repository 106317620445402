<template>

<div id="container">

  <b-alert
      :show="dismissCountDown"
      fade
      variant="danger"
      @dismiss-count-down="countDownChanged"
  >
      Error : {{errorMessage}}
  </b-alert>  


  <div id="header">
    <admin-header-section />
  </div>
  <div id="main">
    <div class="adminContainer">
      <h4>Admin POS Payments 
          <b-button size="sm" variant="success" v-on:click="updateData('T')" v-if="show!='T'">Show Today</b-button>
          <b-button size="sm" variant="success" v-on:click="updateData('Y')" v-if="show!='Y'">Show Yesterday</b-button>
          <b-button size="sm" variant="success" v-on:click="updateData('W')" v-if="show!='W'">Show Week</b-button>
          <b-button size="sm" variant="success" v-on:click="updateData('M')" v-if="show!='M'">Show Month</b-button>
          </h4>
      <br/>
      <b-button size="sm" variant="success" v-on:click="exportData()">Export Data</b-button>
      <b-button size="sm" variant="success" v-on:click="exportSummaryData()">Export Summary Data</b-button>
      
      <div class="adminScroll">
        
        <b-table-simple class="dataTable">
          <b-thead>
            <b-tr class="datalistHeader">
              <b-th>Table</b-th>
              <b-th>Order No</b-th>
              <b-th>Date / Time</b-th>
              <b-th>Amount</b-th>
              <b-th>Actions</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr  :key="index" v-for="col,index in data" class="datalistBody" >
              <b-td>{{data[index].data.tableNo}}</b-td>
              <b-td>{{data[index].data.orderNo}}</b-td>
              <b-td>{{data[index].updated_at}}</b-td>
              <b-td>{{getPayments(data[index].payment_data)}}</b-td>
              <b-td>
                <b-button size="sm" variant="success" v-on:click="resendReceipt(data[index].data.orderNo)">Resend Recipt</b-button>
              </b-td>
            </b-tr>
          </b-tbody>        

        </b-table-simple>
        <h4 v-if="data.length===0">
            No Payments {{data}}
        </h4>      
      </div>
    </div>
  </div>  

</div>

</template>


<script>

import AdminHeaderSection from '../AdminHeaderSection.vue';
import { HTTP } from '@/plugins/http-common';


export default {
  name: 'Admin',
  components:{
    AdminHeaderSection,
  },
  mounted() {
      this.getData()
  },
  data () {  
      return {
            data:[],
            show:'T',
            errorMessage:'',
            dismissCountDown:0,            
      };
  },
  methods: { 
    updateData(show) {
      this.show = show;
      this.getData();
    },
    rowClass() {
      return 'dataTableRow'
    },    
    getData() {
        HTTP.get(
            'api/admin/payments?show=' + this.show,
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.token
                }
            }   
            )
        .then(response => {
            
            console.log(response.data)
            this.data = response.data.payments;

        })
        .catch(e => {

            this.dismissCountDown = 5;
            this.errorMessage = 'Invalid Data pull' + e;

        })                
    },
    exportData() {
        HTTP.get(
            'api/admin/payments/export?summary=N&show=' + this.show,
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.token
                }
            }   
            )
        .then(response => {
            
            const blob = new Blob([response.data], { type: 'text/csv' })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'oenopayments.csv'
            link.click()
            URL.revokeObjectURL(link.href)

        })
        .catch(e => {

            this.dismissCountDown = 5;
            this.errorMessage = 'Invalid Data pull' + e;

        })                
    },

    
    exportSummaryData() {
        HTTP.get(
            'api/admin/payments/export?summary=Y&show=' + this.show,
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.token
                }
            }   
            )
        .then(response => {
            
            const blob = new Blob([response.data], { type: 'text/csv' })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'oenopaymentssummary.csv'
            link.click()
            URL.revokeObjectURL(link.href)

        })
        .catch(e => {

            this.dismissCountDown = 5;
            this.errorMessage = 'Invalid Data pull' + e;

        })                
    },    

    resendReceipt(orderID) {
        HTTP.get(
            'api/admin/payments/resendReceipt?id=' + orderID,
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.token
                }
            }   
            )
        .then(response => {
          
          console.log(response);
          this.dismissCountDown = 5;
          this.errorMessage = 'Receipt Email sent';
          this.dismissCountDownType = 'success';

        })
        .catch(e => {

            this.dismissCountDown = 5;
            this.errorMessage = 'Invalid Data pull' + e;

        })                
    },


    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },    
    getPayments(payments) {
        var paymentAmount = '';
        var totalPayment = 0;


        payments.forEach(paymentItem => {
          const paymentdata = JSON.parse(paymentItem.payment);
          totalPayment = totalPayment + parseFloat(parseInt(paymentdata.amount_money.amount)/100);
          
        });

        if (totalPayment > 0 ) {

          paymentAmount = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(totalPayment);
          
        }
        return paymentAmount;
    },
    createdAt(payment) {
        var createdAt = '';
        if (payment) {
          const paymentdata = JSON.parse(payment);
          createdAt = paymentdata.created_at;
          
        }
        return createdAt;
    }
  },
  computed: {
 
  }  
}      


</script>

<style>

  body {
    font-family: Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    background-color: #131720;
    margin: 0px;
    padding: 0px;
    font-size: 0.8rem;
    overflow: hidden;     
    border:5px solid #131720;
  }

  .admin {
    color:white;
    width:100%;
  }

  .adminScroll{
    height: 70vh;
    overflow-x: hidden;
    overflow-y: auto;    
  }
  .adminContainer {
    height: calc(100%);
    width: 90%;
    margin:auto;
  }
  .adminContainer h4 {
    color:white;
  }
  .adminholderSquare {
    position: relative;
    width: 100%;
    border-radius: 10px;
    background-color: #2e3444;
    height: 20vh !important;
    overflow: hidden;
  }

  .adminholderSquare:after {
    content: "";
    display: block;
    padding-bottom: 100%;
    background-color: #2e3444;    
    margin:30px;
  }

  .adminholderContentholder {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .adminholderDescptionHolder {
    border: 1vw solid transparent;
    min-height: 20vh;
    font-size: 1.6vw;
    font-weight: 700;
    line-height: 1.6vw;   
  }
  .adminOptionRow {
    height: 20vh !important;

  }
  .dataTableRow {
    color:white !important;
  }
  .adminOptionRow a {
    color:white;
  }
  .datalistTable {
      width: 100%;
  }

    .datalistpadded {
        border:1vh solid transparent;
        width:100%;
    }
    .datalistpadded h4 {
      color:white;
    }
    .datalistHeader {
        color:white;
    }
    .datalistBody {
      color:white;
    }
    .datalistBody tr:hover {
      color:white !important;
      background-color: blue !important;
    }
    .datalistActionButton {
        background-color: #b0996f !important;
        color: white !important;
        border-color: #b0996f !important;
        font-size: 1rem !important;
    }     

</style>